<template>
  <div class="sl-searcher">

    <div class="searcher-group">

      <!-- Course selector -->
      <div class="searcher-item">
        <span class="sl-label">Curso</span>
        <select v-model="course" name="course" id="course" class="sl-input">
          <option value="">Seleccione...</option>
          <option value="I1">Infantil 3 años</option>
          <option value="I2">Infantil 4 años</option>
          <option value="I3">Infantil 5 años</option>
          <option value="P1">1º Primaria</option>
          <option value="P2">2º Primaria</option>
          <option value="P3">3º Primaria</option>
          <option value="P4">4º Primaria</option>
          <option value="P5">5º Primaria</option>
          <option value="P6">6º Primaria</option>
        </select>
      </div>

      <!-- Subject selector -->
      <div class="searcher-item">
        <span class="sl-label">Materia</span>
        <select v-model="subject" @change="handleSubject" name="subject" id="subject" class="sl-input">
          <option value="">Seleccione...</option>
          <option v-if="lang == 'es'" value="language/es">Lengua Castellana y Literatura</option>
          <option v-if="lang == 'es'" value="mathematics/es">Matemáticas</option>
          <option v-if="lang == 'es'" value="natural_science/es">Ciencias de la Naturaleza</option>
          <option v-if="lang == 'es'" value="social_science/es">Ciencias Sociales</option>
          <option v-if="lang == 'es'" value="arts/es">Educación Artística</option>
          <option v-if="lang == 'es'" value="physical_education/es">Educación Física</option>
          <option v-if="lang == 'es'" value="citizenship/es">Educación en Valores Cívicos y Éticos</option>
          <option v-if="lang == 'en'" value="language/en">Lengua Extranjera: Inglés</option>
          <option v-if="lang == 'en'" value="mathematics/en">Mathematics</option>
          <option v-if="lang == 'en'" value="natural_science/en">Natural Science</option>
          <option v-if="lang == 'en'" value="social_science/en">Social Science</option>
          <option v-if="lang == 'en'" value="arts/en">Art Education</option>
          <option v-if="lang == 'en'" value="physical_education/en">Physical Education</option>
          <option v-if="lang == 'en'" value="citizenship/en">Civic and Ethical Values Education</option>
          <option v-if="lang == 'fr'" value="*/fr">Lengua Extranjera: Francés</option>
        </select>
      </div>

      <!-- Competence selector -->
      <div class="searcher-item">
        <span class="sl-label">Competencia</span>
        <select v-model="competence" name="competence" id="competence" class="sl-input">
          <option value="">Seleccione...</option>
          <option value="literacy">Competencia en comunicación lingüística</option>
          <option value="multilingual">Competencia plurilingüe</option>
          <option value="stem">Competencia matemática y competencia en ciencia, tecnología e ingeniería</option>
          <option value="digital">Competencia digital</option>
          <option value="initiative_autonomy">Competencia personal, social y de aprender a aprender</option>
          <option value="social_citizenship">Competencia ciudadana</option>
          <option value="entrepreneurship">Competencia emprendedora</option>
          <option value="cultural_awareness">Competencia en conciencia y expresión culturales</option>
        </select>
      </div>

      <!-- Activity selector (only for contents) -->
      <div v-if="!searchPaths" class="searcher-item">
        <span class="sl-label">Tipo de Actividad</span>
        <select v-model="type" name="type" id="type" class="sl-input">
          <option value="">Seleccione...</option>
          <option value="1">Cuento</option>
          <option value="2">Juego</option>
          <option value="3">Escape Room</option>
          <option value="4">Vocabulario</option>
          <option value="5">Lectura</option>
          <option value="6">Actividad</option>
          <option value="7">Audiolibro</option>
          <option value="9">Quiz</option>
          <option value="10">Video</option>
          <option value="12">PDF</option>
        </select>
      </div>

      <!-- Language selector (only for paths) -->
      <div v-if="searchPaths" class="searcher-item">
        <span class="sl-label">Idioma</span>
        <select v-model="selectedLang" :disabled="subject ? true : false" name="lang" id="lang" class="sl-input">
          <option value="es">Español</option>
          <option value="en">Inglés</option>
          <option value="fr">Francés</option>
          <!--<option value="pt">Portugués</option>-->
          <!--<option value="it">Italiano</option>-->
          <!--<option value="ca">Catalán</option>-->
          <!--<option value="la">Español LatAm</option>-->
        </select>
      </div>
    </div>

    <!-- Text search -->
    <div class="searcher-group">
      <div class="searcher-item searcher">
        <span class="sl-label">Buscador</span>
        <input v-model="searchword" type="text" class="sl-input" @keypress="handleKeyPress">
      </div>

      <!-- Search button -->
      <div class="searcher-item searcher-button center">
        <button 
          :class="[searching ? 'sl-searcher-button-serching' : 'sl-searcher-button pointer']" 
          :disabled="searching" 
          @click="search"
        >
          <svg 
            v-if="searching" 
            aria-hidden="true" 
            focusable="false" 
            data-prefix="fas" 
            data-icon="spinner" 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512" 
            class="svg-inline--fa fa-spinner fa-w-16 fa-spin fa-lg"
          >
            <path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" class=""></path>
          </svg>
          {{ searching? 'Buscando' : 'Buscar' }}
        </button>
      </div>
    </div>

    <!-- Header -->
    <div :class="[searchPaths ? 'sl-contents-header' : 'sl-contents-header sl-contents-header-grid']">
      <h3 v-if="searchPaths">
        Secuencias Didácticas
      </h3>
      <h3 v-else>
        Objetos de Aprendizaje
      </h3>

      <h3 v-if="!searchPaths" class="show-when-big">
        Recursos seleccionados
      </h3>
    </div>
  </div>
</template>

<script>
export default {

  // Component name.
  name: "SearchEngine",

  // Data.
  data () {
    return {
      searchword: '',
      course: '',
      subject: '',
      competence: '',
      type: '',
      selectedLang: '',
    }
  },

  // Input properties.
  props: {
    // Current selected language (only for contents).
    lang: String,

    // Whether we are searching for contents (false) or for paths (true).
    searchPaths: Boolean,

    // Whether we are editing an activity or customizing a path.
    editing: Boolean,

    // Whether search is taking place.
    searching: Boolean,
  },
  
  // Emits.
  emits: [
    'change-lang',
    'search',
  ],

  // Code executed for handling events when the page is loaded.
  mounted () {
    this.selectedLang = this.lang
    if (this.editing) this.search()
  },

  // Triggers.
  watch: {
    
    // When the selected language is changed (only for routes)
    selectedLang () {
      this.$emit('change-lang', this.selectedLang)
    }
  },

  // Methods.
  methods: {

    /** 
     * Handle how language is affected by the selected subject.
     */
    handleSubject () {
      switch (this.subject) {
        case 'language/es':
        case 'mathematics/es':
        case 'social_science/es':
        case 'natural_science/es':
        case 'arts/es':
        case 'physical_education/es':
        case 'citizenship/es':
          this.selectedLang = 'es'
          break
        case 'language/en':
        case 'mathematics/en':
        case 'social_science/en':
        case 'natural_science/en':
        case 'arts/en':
        case 'physical_education/en':
        case 'citizenship/en':
          this.selectedLang = 'en'
          break
        case 'language/fr':
        case '*/fr':
          this.selectedLang = 'fr'
          break
        default:
          this.selectedLang = this.lang
      }
    },

    /**
     * Handles the event of a key press in the search input.
     */
    handleKeyPress (e) {
      if (e.key === 'Enter') {
        this.search()
      }
    },

    /**
     * Perform the search. 
     */
    search () {
      let data = {
        q: this.searchword,
        course: this.course,
        subject: this.subject,
        competence: this.competence,
        lang: this.selectedLang,
        type: this.type,
      }
      this.$emit('search', data)
    }
  }
}
</script>

<style scoped>
/* SPINNER */
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa.fa-lg {
  vertical-align: -0.225em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}
.fa-lg {
  font-size: 1.33333em;
  line-height: .75em;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.searcher-button{
  flex-grow: 0;
}
</style>